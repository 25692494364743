import React, {useState} from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useGlobal } from 'reactn';
import { useMutation } from '@apollo/client';
import { QUERIES } from '../../../api/queries';
import { makeStyles } from '@material-ui/core/styles';
import { showErrorToast, showInfoToast } from '../../../util/toasts';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal+1,
    color: 'white',
  },
}));

const CustomerDetail=({bestekkenClients, showDetailDialog, setShowDetailDialog, selectedCustomer, refetch})=>{
    const classes = useStyles();
    const [authUser, setAuthUser] = useGlobal('authUser');

    const [addressInput, setAddressInput] = useState("");
    const [nameInput, setNameInput] = useState("");
    const [bestekkenSelect, setBestekkenSelect] = useState(null);
    const [bestekkenSearchString, setBestekkenSearchString] = useState("");
    const [hasValues, setHasValues] = useState(false);
    const [loading, setloading] = useState();

    const [createPartnerMutation] = useMutation(QUERIES.CREATE_PARTNER);
    const [updatePartnerMutation] = useMutation(QUERIES.UPDATE_PARTNER);

    useEffect(()=>{
        if(!!selectedCustomer){
            setNameInput(selectedCustomer.name);
            setAddressInput(selectedCustomer.address);
            setBestekkenSelect(selectedCustomer.api_client_id);
            setBestekkenSearchString(getSelectedClientName(selectedCustomer.api_client_id));
        }
    },[selectedCustomer]);

    useEffect(()=>{
        setHasValues(valueChecker());
    },[addressInput, nameInput]);

    const handleClose=()=>{
        setShowDetailDialog(false);
        setAddressInput("");
        setNameInput("");
        setBestekkenSelect(null);
        setloading(false);
    }

    const handleConfirm=async()=>{
        try{
            setloading(true);
            if(!!selectedCustomer){
                await updatePartnerMutation({
                    variables: {
                        partner: {
                            id: selectedCustomer.id,
                            name: nameInput,
                            address: addressInput,
                            api_client_id: bestekkenSelect,
                            modify_user: authUser.profileObj.email,
                        }
                    }
                });
            }else{
                await createPartnerMutation({
                    variables: {
                        partner: {
                            name: nameInput,
                            address: addressInput,
                            flag_supplier: false,
                            flag_customer: true,
                            create_user: authUser.profileObj.email,
                            api_client_id: bestekkenSelect,
                            modify_user: authUser.profileObj.email,
                        }
                    }
                });
            }

            await refetch();
            handleClose();
            showInfoToast("Partner succesvol verwerkt");
        }catch(err){
            //TODO: alert? wtf
            showErrorToast("Er ging iets mis bij het uitvoeren van de partner actie.");
            handleClose();
        }
    }

    const valueChecker=()=>{
        return !!addressInput&&addressInput!="" && !!nameInput&&nameInput!="" && !!authUser&&authUser.profileObj&&authUser.profileObj.email;
    }

    const getSelectedClientName=(id)=>{
        const res = bestekkenClients.find((client)=>{
            return client.clientId==id;
        });
        return res?res.name:"";
    }

    return(
        <>
        <Dialog open={showDetailDialog} onClose={handleClose} maxWidth="md" fullWidth={true}>
                    <DialogTitle>Klant details</DialogTitle>
                    <Divider style={{width: "95%", margin: "auto", marginBottom: 15}} />
                    <DialogContent >
                        <div style={{width: "90%", margin: "auto"}}>
                            <TextField disabled={true} label="Klant Id" style={{width: "40%", float: "left", padding: 5}} value={selectedCustomer?selectedCustomer.partner_id:"/"} />
                            <TextField label="Adres" style={{width: "40%", float: "right", padding: 5}} defaultValue={addressInput} onChange={(e)=>setAddressInput(e.target.value)} />
                            <TextField label="Naam" style={{width: "40%", float: "left", padding: 5, marginTop: 50}} defaultValue={nameInput} onChange={(e)=>setNameInput(e.target.value)} />
                            <Autocomplete
                            options={bestekkenClients?bestekkenClients:[]}
                            getOptionLabel={(option) => option.name}
                            style={{ width: "40%", float: "right", padding: 5, marginTop: 50 }}
                            value={bestekkenSelect}
                            onChange={(e, value)=>setBestekkenSelect(value?value.clientId:null)}
                            inputValue={bestekkenSearchString}
                            onInputChange={(e, val)=>{
                                if(!!e)
                                    setBestekkenSearchString(val);
                            }}
                            renderInput={(params) => <TextField {...params} label="Bestekken.net klant" variant="outlined" />}
                            />
                        </div>
                    </DialogContent>
                    <br style={{marginBottom: 15, marginTop: 15}} />
                    <DialogActions>
                        <Button color="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        <Button color="primary" disabled={!hasValues} onClick={handleConfirm}>
                            {!!selectedCustomer?"Bewaren":"Toevoegen"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                </>
    );
}

export default CustomerDetail;