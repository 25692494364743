import { AppBar, IconButton, Typography, Toolbar, Grid, Drawer, List, ListItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { getGlobal, useGlobal, useState } from 'reactn';

import Logout from '../Logout/Logout';
import AtnDrawer from './AtnDrawer';

const HeaderMenu = props => {
    const authUser = getGlobal('authUser');
    const [showDrawer, setShowDrawer] = useState(false);
    const history = useHistory();
    const [currentUser, setCurrentUser] = useGlobal('currentUser');

    const toggleDrawer=(val)=>{
        const state = val?val:!showDrawer;
        setShowDrawer(state);
    }

    if (!!currentUser) {
        console.log('current user: ', currentUser)
        if (currentUser.fk_ctx_security_role === "1" || currentUser.fk_ctx_security_role === "2") {
            return (
                <>
                <AppBar position="static" style={{width: "100vw"}}>
                    <Toolbar variant="dense">
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => toggleDrawer(!showDrawer)}>
                            <MenuIcon />
                        </IconButton>
                        <Grid
                            container
                            spacing={6}
                            direction='row'
                            alignItems='center'
                            justify='space-between'
        
                        >
                            <Grid item>
                                <Typography variant="h6" color="inherit">
                                    ATN Projects
            </Typography>
                            </Grid>
                            {!!authUser && (
                                <Grid item>
                                <Logout />
                            </Grid>
                            )}
                        </Grid>
                    </Toolbar>
                </AppBar>
        
                <AtnDrawer showDrawer={showDrawer} toggleDrawer={toggleDrawer}/>
                </>
            );
        }
    }

    return (<>
    
    <AppBar position="static" style={{width: "100vw"}}>
                    <Toolbar variant="dense">
                        <Grid
                            container
                            spacing={6}
                            direction='row'
                            alignItems='center'
                            justify='space-between'
        
                        >
                            <Grid item>
                                <Typography variant="h6" color="inherit">
                                    ATN Projects
            </Typography>
                            </Grid>
                            {!!authUser && (
                                <Grid item>
                                <Logout />
                            </Grid>
                            )}
                        </Grid>
                    </Toolbar>
                </AppBar>
        
    </>)
}

export default HeaderMenu;