import React, { useEffect, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useQuery } from '@apollo/client';
import { QUERIES } from '../../../api/queries';
import Button from '@material-ui/core/Button';

const divStyle = {
    margin: "1em",
    minWidth: "100%",
    minHeight: "400px", 
    borderTop: "1px solid #cccccc",
    padding: "10px",
  };
function SecurityRoles() {
    const [securityRoles, setSecurityRoles] = useState([]);

    const cols = [
        { field: 'name', headerName: 'Naam', width: 250 },
        { field: 'code', headerName: 'Code', width: 250 },
        { field: 'create_user', headerName: 'Aangemaakt door', width: 250 },
        
    ];

    const { loading, error, data, refetch } = useQuery(QUERIES.SECURITYROLES, {
        fetchPolicy: 'cache-first',
        variables: {  }
    });

    useEffect(()=>{
        console.log(data)
        if(data&&data.securityRoles)
        setSecurityRoles(data.securityRoles);
    },[data]);

    return (
        <div style={divStyle}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={1}>
                    <>
                        <Grid item>
                            <Typography variant='h5' style={{marginBottom: '0', textAlign: "center"}}>Rollen</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ height: '30vh', width: '90vw', overflowX:'hidden' }}>
                                <DataGrid key={securityRoles?securityRoles:[]} columns={cols} rows={securityRoles?securityRoles:[]} sortModel={[{ field: 'name', sort: 'desc' }]}></DataGrid>
                            </div>
                        </Grid>
                    </>               
            </Grid>
        </div>
    );
}
 

 
export default SecurityRoles;