import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, IconButton, CircularProgress, Button, Dialog } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudIcon from '@material-ui/icons/Cloud';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DataGrid } from '@material-ui/data-grid';
import { useQuery } from '@apollo/client';
import { QUERIES } from '../../../api/queries';

import ProjectDetails from './ProjectDetails/ProjectDetails';

const driveUrlBase = 'https://drive.google.com/drive/folders/';

const ProjectOverview = props => {

    const [projects, setProjects] = useState(null);
    const [partners, setPartners] = useState(null);
    const [fiscalYears, setFiscalYears] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState(null);
    const [projectDetailsVisible, setProjectDetailsVisible] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(new Date().getFullYear());
    const [filter, setFilter] = useState({
        fiscalYear: { id: '-1', name: new Date().getFullYear() },
        partner: { name: '', id: '-1' },
        owner: { email: '' },
        projectName: { name: '' },
        projectId: { project_id: '' }
    });
    const cols = [
        {
            field: 'id', headerName: 'Bewerken', renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        setSelectedProject(params.data);
                        setProjectDetailsVisible(true);
                    }}
                >
                    <EditIcon />
                </IconButton>
            )
        },
        { field: 'project_id', headerName: 'Project ID' },
        {
            field: 'name', headerName: 'Naam', width: 400, renderCell: (params) => {
                return (<b>{params.value}</b>)
            }
        },
        {
            field: 'partner', headerName: 'Klant', width: 200, valueGetter: (params) => params.getValue('partner').name || '-',
            sortComparator: (v1, v2, row1, row2) => {
                if (row1.data.partner.name.toLowerCase().trim() > row2.data.partner.name.toLowerCase().trim()) {
                    return 1;
                }
                if (row2.data.partner.name.toLowerCase().trim() > row1.data.partner.name.toLowerCase().trim()) {
                    return -1;
                }
                return 0
            }
        },
        {
            field: 'folder_id', headerName: 'Drive map', renderCell: ({ value }) => (<IconButton href={driveUrlBase + value} target='_blank'>
                <CloudIcon />
            </IconButton>)
        },
        {
            field: 'works', headerName: 'Werken',
            //valueGetter: params => params.getValue('works').length || '-'
            renderCell: (params) => (<Button color="primary" variant="outlined"
                onClick={() => props.history.push(`/works/${params.data.id}`)}//(<Redirect to={`/works/${params.data.id}`}/>)}
            >{params.value.length}</Button>)
        },
        { field: 'create_date', valueFormatter: ({ value }) => new Date(value).toLocaleDateString('nl-BE'), headerName: 'Gemaakt op', width: 130 },
        { field: 'modify_date', valueFormatter: ({ value }) => new Date(value).toLocaleDateString('nl-BE'), headerName: 'Gewijzigd op', width: 130 },
        { field: 'owner', headerName: 'Eigenaar', width: 180 },
        {
            field: 'fiscalYear', headerName: 'Boekjaar', valueGetter: params => params.getValue('fiscalYear').name || '-',
            sortComparator: (v1, v3, row1, row2) => Number(row1.data.fiscalYear.name) - Number(row2.data.fiscalYear.name)
        },
    ];

    const filterProjects = () => {
        let res = [...projects];
        for (let el of Object.keys(filter)) {
            if (el === 'fiscalYear') {
                if (filter[el].id != -1) {
                    res = res.filter(rEl => filter.fiscalYear.id === rEl.fiscalYear.id);
                    setSelectedFiscalYear(`${filter[el].name}`)
                }
            }
            if (el === 'partner') {
                if (filter[el].id != -1) {
                    res = res.filter(rEl => filter.partner.name === rEl.partner.name);
                }
            }
            if (el === 'owner') {
                if (filter[el].email !== '') {
                    res = res.filter(rEl => filter.owner.email === rEl.owner);
                }
            }
            if (el == 'projectName') {
                if (filter[el].name !== '') {
                    res = res.filter(rEl => filter.projectName.name === rEl.name);
                }
            }
            if (el == 'projectId') {
                if (filter[el].project_id !== '') {
                    res = res.filter(rEl => filter.projectId.project_id === rEl.project_id);
                }
            }
        }
        setFilteredProjects(res);
    }

    useEffect(() => {
        if (!!projects) {
            filterProjects()
        }
    }, [projects, filter]);

    const { loading, error, data, refetch } = useQuery(QUERIES.PROJECT_OVERVIEW, {
        fetchPolicy: 'cache-first',
        // pollInterval: 20000,
        // onCompleted: res => {
        //     // console.log(res.atnProjects);
        //     setProjects(res.atnProjects);
        //     // console.log(res.partners);
        //     let pSorted = [...res.partners, { name: '', id: '-1' }]
        //     // console.log(pSorted);
        //     pSorted.sort((a, b) => {
        //         return a.name.toLowerCase().trim() < b.name.toLowerCase().trim() ? -1 : 1
        //     })
        //     setPartners(pSorted);
        //     let fySorted = [...res.fiscalYears, {name: '', id: '-1'}]
        //     fySorted.sort((a, b) => (Number(a) > Number(b)? 1: -1));
        //     setFiscalYears(fySorted);
        //     setGoogleUsers([...res.googleUsers, {email: ''}]);
        // },
        variables: { fiscalYear: `${selectedFiscalYear}` }
    });

    const handleData = data => {
        setProjects(data.atnProjects);
        // console.log(res.partners);
        let pSorted = [...data.partners, { name: '', id: '-1' }]
        // console.log(pSorted);
        pSorted.sort((a, b) => {
            return a.name.toLowerCase().trim() < b.name.toLowerCase().trim() ? -1 : 1
        })
        setPartners(pSorted);
        let fySorted = [...data.fiscalYears, { name: '', id: '-1' }]
        fySorted.sort((a, b) => (Number(a) > Number(b) ? 1 : -1));
        setFiscalYears(fySorted);
        setEmployees([...data.employees, {email: ''}]);
    }

    useEffect(() => {
        if (data) {
            console.log('Data effect hook:', data);
            handleData(data);
        }
    }, [data])

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}>
            <Grid item>
                <Typography variant='h2'>Projectoverzicht</Typography>
                <Dialog open={projectDetailsVisible}>
                    <ProjectDetails
                        visible={projectDetailsVisible}
                        projectId={selectedProject ? selectedProject.id : null}
                        handleClose={() => setProjectDetailsVisible(false)}
                        handleSave={async () => {
                            setProjectDetailsVisible(false)
                            refetch().then(res => {
                                console.log('Refetch res: ', res)
                                handleData(res.data);
                            })
                        }} />
                </Dialog>
            </Grid>
                {!!filteredProjects && !!partners && !!fiscalYears && !!employees ? (
                <>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            spacing={4}>
                            <Grid item>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<FilterListIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography >Filter</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                            spacing={4}>
                                                <Grid item>
                                            <Autocomplete
                                                options={projects}
                                                getOptionLabel={(option) => option.project_id}
                                                style={{ width: 200, margin: 8 }}
                                                value={filter.projectId}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, projectId: newValue ? newValue : { project_id: '' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ID" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={projects}
                                                getOptionLabel={(option) => option.name}
                                                style={{ width: 400, margin: 8 }}
                                                value={filter.projectName}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, projectName: newValue ? newValue : { name: '' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Naam" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={partners}
                                                getOptionLabel={(option) => option.name}
                                                style={{ width: 600, margin: 8 }}
                                                value={filter.partner}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, partner: newValue ? newValue : { name: '', id: '-1' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Klant" variant="outlined" />}
                                            />
                                            </Grid>
                                            <Grid item>
                                            <Autocomplete
                                                options={employees}
                                                getOptionLabel={(option) => option.email? option.email: ''}
                                                style={{ width: 400, margin: 8 }}
                                                value={filter.owner}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, owner: newValue ? newValue : { email: '' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Eigenaar" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={fiscalYears}
                                                getOptionLabel={(option) => option.name}
                                                style={{ width: 400, margin: 8 }}
                                                value={filter.fiscalYear}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, fiscalYear: newValue ? newValue : { name: '', id: '-1' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Boekjaar" variant="outlined" />}
                                            />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => {
                                    setSelectedProject(null)
                                    setProjectDetailsVisible(true)
                                }}>
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div style={{ height: '80vh', width: '90vw' }}>
                            <DataGrid key={(filteredProjects && filteredProjects.length > 0) ? filteredProjects : []} columns={cols} rows={[...filteredProjects]} sortModel={[{ field: 'modify_date', sort: 'desc' }]}></DataGrid>
                        </div>
                    </Grid>
                </>
            ) : (<CircularProgress />)}
        </Grid>
    );
}

export default ProjectOverview;