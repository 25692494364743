import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField
} from '@material-ui/core';
import './WorkDetails.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { QUERIES } from '../../../api/queries';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { showErrorToast, showInfoToast } from '../../../util/toasts';



const WorkDetails = props => {
        const [authUser, setAuthUser] = useGlobal('authUser');
        const [createWork] = useMutation(QUERIES.CREATE_WORK);
        const [updateWork] = useMutation(QUERIES.UPDATE_WORK);

        const workModel = {
            id: null,
            name: '',
            fk_ctx_project: null,
            fk_ctx_project_type: null,
            fk_ctx_work_status: null,
            fk_ctx_employee: null,
            owner: null,
            comment: null,
            estimate_worth: 0
        }

        const [selectedProject, setSelectedProject] = useState(null);
        const [selectedProjectType, setSelectedProjectType] = useState(null);
        const [selectedWorkStatus, setSelectedWorkStatus] = useState(null);
        const [selectedProfitCenter, setSelectedProfitCenter] = useState(null);
        const [claimId, setClaimId] = useState(null);
        const [billingId, setBillingId] = useState(null);
        const [inBestekken, setInBestekken] = useState(false);
        const [newInBestekken, setNewInBestekken] = useState(false);
        const [profitCenters, setProfitCenters] = useState(null);
        const [selectedUser, setSelectedUser] = useState(null)
        const [postObj, setPostObj] = useState(null)
        const { data, error, loading } = useQuery(QUERIES.WORK_DETAILS, { variables: { id: props.workId, fiscalYear: props.fiscalYear }, fetchPolicy: 'network-only' })
        const { PCdata, PCerror, PCloading, refetch  } = useQuery(QUERIES.BESTEKKEN_PROFITCENTERS, { variables: {projectId: selectedProject?selectedProject.bestekken_project_id:''}, fetchPolicy: 'network-only'});
        // const [loadData, {bestekkenData}] = useMutation(QUERIES.SEND_TO_BESTEKKEN, { variables: { bestekkenInput: postObj }}, {
        //     onCompleted(data) {
        //       console.log('resData', data);
        //     }
        //   });

        const [loadData] = useMutation(QUERIES.SEND_TO_BESTEKKEN);

        const [currentWork, setCurrentWork] = useState({ ...workModel })
        const [estimateDate, setEstimateDate] = useState(new Date().getTime())//useState(new Date().toISOString().split('T')[0]);

        useEffect(() => {
            if (!!data) {
                // console.log('authUser', authUser)
                if (!data.atnWork) {
                    setSelectedUser(findUserId(authUser.getBasicProfile().getEmail()))
                    setSelectedWorkStatus(data.workStatuses.find(el => el.name === 'Nieuw'))
                }
               
                else {
                    setSelectedUser({ email: data.atnWork.owner })
                    setSelectedProject(data.atnWork.project)
                    setSelectedProjectType(data.atnWork.projectType)
                    // setEstimateDate(data.atnWork.estimate_date? data.atnWork.estimate_date.split('T')[0]: new Date().toISOString().split('T')[0]);
                    setEstimateDate(data.atnWork.estimate_date? new Date(data.atnWork.estimate_date).getTime(): new Date().getTime());
                    setSelectedWorkStatus(data.atnWork.workStatus)
                    
                    setSelectedProfitCenter(data.atnWork.apk_profit_center_id)
                    setCurrentWork(old => ({
                        ...old,
                        comment: data.atnWork.comment,
                        name: data.atnWork.name,
                        id: data.atnWork.id,
                        estimate_worth: data.atnWork.estimate_worth
                    }))
                }
            }
        }, [data]);

        useEffect(() => {
            if (selectedUser) {
                setCurrentWork(old => ({ ...old, owner: selectedUser.email, fk_ctx_employee: selectedUser.id }))
            }
            if (selectedProject) {
                setCurrentWork(old => ({ ...old, fk_ctx_project: selectedProject.id }))
            }
            if (selectedProjectType) {
                setCurrentWork(old => ({...old, fk_ctx_project_type: selectedProjectType.id}) )
            }
            if (estimateDate) {
                setCurrentWork(old => ({...old, estimate_date: estimateDate}));
            }
            if (selectedWorkStatus) {
                console.log('selectedWorkStatus.code',selectedWorkStatus.id)

                if (selectedWorkStatus.id == '7'){
                    console.log('in bestekken handler')
                    document.getElementById('BestekkenButton').style.display= 'none';
                    setInBestekken(true)
                    setCurrentWork(old => ({...old, fk_ctx_work_status: 7}));
                }else{
                    setCurrentWork(old => ({...old, fk_ctx_work_status: selectedWorkStatus.id}));
                }
            }
            if (selectedProfitCenter){
                setCurrentWork(old => ({...old, apk_profit_center_id: selectedProfitCenter._id?selectedProfitCenter._id:selectedProfitCenter}));

            }
            if (claimId) {
                setCurrentWork(old => ({...old, apk_claim_id: claimId}));
            }
            if (billingId) {
                setCurrentWork(old => ({...old, apk_billing_id: billingId}));
            }
        }, [selectedUser, selectedProject, selectedProjectType, estimateDate, selectedWorkStatus, selectedProfitCenter, claimId, billingId]);

        useEffect(() => {
        }, [currentWork]);

        useEffect(async () => {
            if (!!selectedProject){
                refetch().then((res)=>{
                    setProfitCenters(res.data.bestekkenProfitcenters)
                });   
            }
        }, [selectedProject]);

        useEffect( () => {
            if (!!profitCenters){
                searchPC()
            }
        }, [profitCenters]);

        useEffect(()=>{
            if (!!postObj){
                loadData({ variables: { bestekkenInput: postObj }})
                .then(async(res)=>{
                    console.log('res', res)
                    if (res.data.sendToBestekken.status == 200){
                        showInfoToast(res.data.sendToBestekken.message);
                        setSelectedWorkStatus({id:7});
                        setClaimId(res.data.sendToBestekken.apk_claim_id);
                        setInBestekken(true);
                        setNewInBestekken(true);
                        setBillingId(res.data.sendToBestekken.billing_id);
                        //await saveThisWork();
                    }else{
                        showErrorToast('Status: ' + res.data.sendToBestekken.status + ' \n Error:' + res.data.sendToBestekken.message );
                    }
                })
                .catch((err)=>{console.log('error', err)});
            }
        }, [postObj])

        function findUserId(email){
            let employee = data.employees.find(employee => employee.email == email)
            console.log('employee', employee)
            return { email: email, id: employee.id }
        }

        function searchPC(){
            profitCenters.find((el)=>{
                if (el._id == currentWork.apk_profit_center_id){
                    setSelectedProfitCenter(el);
                }
            })
        }

        async function sendToBestekken(){
                if (!!selectedProject){
                    console.log('data.atnWork', data.atnWork)
                    var awNummer = data.atnWork.work_id;
                    var projectCode = data.atnWork.project.bestekken_project_id;
                    var customerCode = data.atnWork.project.partner.api_client_id ;
                    var profitcenterId = data.atnWork.apk_profit_center_id;
                    var claimAmount = data.atnWork.estimate_worth ;
                    var estimate_date = data.atnWork.estimate_date ;
                    
                    if (customerCode != null) {
                        if (profitcenterId != null) {
                            if (claimAmount != 0) {
                                setPostObj({
                                    awNummer: awNummer,   
                                    projectCode: projectCode,  
                                    customerCode: customerCode, 
                                    profitcenterId: profitcenterId, 
                                    claimAmount: claimAmount, 
                                    estimate_date: estimate_date,  
                                })
                            } else {
                                showErrorToast("Geen waarde toegevoegd aan deze offerte.");
                            }
                        } else {
                            showErrorToast("Geen profitcenter gekoppeld aan dit werk");
                
                        }
                    } else {
                        showErrorToast("Geen bestekkenClient gekoppeld aan deze klant.");
                    }
                }
        }

       

        const submit = () => {
            confirmAlert({
              title: 'Versturen naar bestekken',
              message: 'Zeker dat je het naar bestekken wil versturen?',
              buttons: [
                {
                  label: 'Ja',
                  onClick: () => {
                    sendToBestekken();
                  }
                },
                {
                  label: 'Nee',
                  onClick: () =>{console.log('no')}
                }
              ]
            });
          };


         async function saveThisWork() {
             console.log('currentWork', currentWork)
            if (!props.workId) {
                try {
                    const mutationRes = await createWork({
                        variables: {
                            work: {
                                ...currentWork,
                                create_user: authUser.getBasicProfile().getEmail(),
                                modify_user: authUser.getBasicProfile().getEmail(),
                                estimate_date: new Date(currentWork.estimate_date).toISOString().split('T')[0]
                            }
                        // },
                        // update: (cache, { data: { createATNWork } }) => {
                        //     console.log(data);
                        //     const cacheData = { ...cache.readQuery({ query: QUERIES.WORK_OVERVIEW, 
                        //         variables: {fiscalYear: selectedProject.fiscalYear.name, projectId: null} }) };
                        //     console.log('cacheData: ', cacheData, data);
                        //     cacheData.atnWorks = [...cacheData.atnWorks, createATNWork];
                        //     cache.writeQuery({ query: QUERIES.WORK_OVERVIEW, data: cacheData });
                        }
                    });
                    console.log('mutationRes', mutationRes)
                    props.handleSave();
                }
                catch (err) {
                    console.error('mutation error: ', err);
                }
            }
            else {
                try {
                    const updateRes = await updateWork({
                        variables: {
                            work: {
                                ...currentWork,
                                modify_user: authUser.getBasicProfile().getEmail(),
                                estimate_date: new Date(currentWork.estimate_date).toISOString().split('T')[0]
                                // estimate_date: new Date(currentWork.estimate_date).toLocaleDateString('nl-BE')
                            }
                        },
                        // update: (cache, { data: { updateATNWork } }) => {
                        //     const cacheData = { ...cache.readQuery({ query: QUERIES.WORK_OVERVIEW }) };
                        //     // cacheData.atnProjects = [...cacheData.atnProjects, createATNProject];
                        //     const newWorks = cacheData.atnWorks.map(el => el.id === updateATNWork.id ? updateATNWork : el);
                        //     console.log('newProjects: ', newWorks)
                        //     cache.writeQuery({ query: QUERIES.WORK_OVERVIEW, data: { ...cacheData, atnWorks: newWorks } });
                        // }
                    });
                    console.log('updateRes', updateRes)

                    props.handleSave();
                }
                catch (err) {
                    console.error('mutation error: ', err);
                }
            }
          }


        return (
            !!data && (
                <>
                    <>
                        <DialogTitle id="form-dialog-title">{data.atnWork ? ('Werk ' + data.atnWork.work_id) : 'Nieuw Werk'}</DialogTitle>
                        <DialogContent>
                        <Grid
                        container
                        direction="row"
                        justify="center"
                        // alignItems="center"
                        spacing={4}>
                            <Grid item>
                                <div>
                                <TextField
                                disabled={inBestekken}
                                autoFocus
                                margin="dense"
                                label="Naam"
                                fullWidth
                                value={currentWork.name}
                                onChange={(e) => {
                                    setCurrentWork(old => ({ ...old, name: e.target.value }))
                                }}
                            />
                            <Autocomplete
                                disabled={!!props.workId || inBestekken}
                                options={data.atnProjects}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 360, margin: 8 }}
                                value={selectedProject}
                                onChange={(event, newValue) => {
                                    if (!!newValue) {
                                        setSelectedProject(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Project" variant="outlined" />}
                            />
                            <Autocomplete
                                options={data.employees}
                                disabled={inBestekken}
                                getOptionLabel={(option) => option.email? option.email: ''}
                                style={{ width: 360, margin: 8 }}
                                value={selectedUser}
                                onChange={(event, newValue) => {
                                    if (!!newValue) {
                                        setSelectedUser(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Eigenaar" variant="outlined" />}
                            />
                            <Autocomplete
                                options={data.projectTypes}
                                disabled={inBestekken}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 360, margin: 8 }}
                                value={selectedProjectType}
                                onChange={(event, newValue) => {
                                    setSelectedProjectType(newValue ? newValue : { _id: '1', name: 'Fiber' })
                                }}
                                renderInput={(params) => <TextField {...params} label="Werktype" variant="outlined" />}
                            />
                            <TextField
                                style={{display: props.workId? 'block': 'none'}}
                                disabled={inBestekken}
                                autoFocus
                                margin="dense"
                                label="Opmerking"
                                fullWidth
                                value={currentWork.comment}
                                onChange={(e) => {
                                    setCurrentWork(old => ({ ...old, comment: e.target.value }))
                                }}
                            />
                            </div>
                        </Grid>
                        <Grid item>
                            <div>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                disabled={inBestekken}
                                variant="inline"
                                format="DD/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Offertedatum"
                                value={new Date(estimateDate)}
                                onChange={e => {
                                    // setEstimateDate(e.toISOString().split('T')[0]);
                                    setEstimateDate(e.toISOString())
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            </MuiPickersUtilsProvider>
                            <TextField
                                style={{display: props.workId? 'block': 'none'}}
                                disabled={inBestekken}
                                autoFocus
                                margin="dense"
                                label="Waarde offerte"
                                fullWidth
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                }}
                                value={currentWork.estimate_worth}
                                onChange={(e) => {
                                    setCurrentWork(old => ({ ...old, estimate_worth: Number(e.target.value) }))
                                }}
                            />
                            <Autocomplete
                                disabled={inBestekken}
                                options={data.workStatuses}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 360, margin: 8 }}
                                value={selectedWorkStatus}
                                onChange={(event, newValue) => {
                                    if (!!newValue) {
                                        setSelectedWorkStatus(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                            />
                            <Autocomplete
                                disabled={inBestekken }
                                options={profitCenters?profitCenters:[]}
                                getOptionLabel={(option) => option.name}
                                style={{ width: 360, margin: 8 }}
                                value={selectedProfitCenter}
                                onChange={(event, newValue) => {
                                    if (!!newValue) {
                                        setSelectedProfitCenter(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Profit Center" variant="outlined" />}
                            />  
                            <Button variant="contained" color="primary" onClick={() => {submit()} } id="BestekkenButton" disabled={inBestekken && newInBestekken} >
                                
                                Verstuur naar Bestekken
                            </Button>
                            </div>
                        </Grid>
                        </Grid>
                        </DialogContent>
                    </>
                    <DialogActions>
                        <Button onClick={props.handleClose} color="primary" disabled={newInBestekken}>
                            Annuleren
                        </Button>
                        <Button 
                        // disabled={inBestekken}
                        onClick={async () => {
                            await saveThisWork(); // changed the code here to a function so we can call this onclick event when bestekken returns 200
                        }} color="primary">
                            Opslaan
            </Button>
                    </DialogActions>
                </>
            )
            // </Dialog>
        );
   
};
export default WorkDetails;