import React, {useState} from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useGlobal } from 'reactn';
import { useMutation } from '@apollo/client';
import { QUERIES } from '../../../api/queries';
import { makeStyles } from '@material-ui/core/styles';
import { showErrorToast, showInfoToast } from '../../../util/toasts';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal+1,
    color: 'white',
  },
}));

const EmployeeDetail=({securityRoles, showDetailDialog, setShowDetailDialog, selectedEmployee, refetch})=>{
    const classes = useStyles();
    const [authUser, setAuthUser] = useGlobal('authUser');

    const [emailInput, setEmailInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");
    const [securityRoleSelect, setSecurityRoleSelect] = useState(null);
    const [securityRoleSearchString, setSecurityRoleSearchString] = useState("");
    const [firstnameInput, setFirstnameInput] = useState("");
    const [lastnameInput, setLastnameInput] = useState("");
    const [hasValues, setHasValues] = useState(false);
    const [loading, setloading] = useState();

    const [createEmployeeMutation] = useMutation(QUERIES.CREATE_EMPLOYEE);
    const [updateEmployeeMutation] = useMutation(QUERIES.UPDATE_EMPLOYEE);

    useEffect(()=>{
        if(!!selectedEmployee){
            setFirstnameInput(selectedEmployee.firstname);
            setLastnameInput(selectedEmployee.lastname);
            setEmailInput(selectedEmployee.email);
            setPhoneInput(selectedEmployee.contact_phone);
            setSecurityRoleSelect(selectedEmployee.fk_ctx_security_role);
            setSecurityRoleSearchString(getSelectedSecurityRoleName(selectedEmployee.fk_ctx_security_role));
        }
    },[selectedEmployee]);

    useEffect(()=>{
        setHasValues(valueChecker());
    },[emailInput, firstnameInput, lastnameInput, phoneInput]);

    const handleClose=()=>{
        setShowDetailDialog(false);
        // setEmailInput("");
        // setPhoneInput("");
        // setFirstnameInput("");
        // setLastnameInput("");
        setloading(false);
    }

    const handleConfirm=async()=>{
        try{
            setloading(true);
            if(!!selectedEmployee){
                await updateEmployeeMutation({
                    variables: {
                        employee: {
                            id: selectedEmployee.id,
                            firstname: firstnameInput,
                            lastname: lastnameInput,
                            email: emailInput,
                            contact_phone: phoneInput,
                            modify_user: authUser.profileObj.email,
                            fk_ctx_security_role: securityRoleSelect
                        }
                    }
                });
            }else{
                await createEmployeeMutation({
                    variables: {
                        employee: {
                            firstname: firstnameInput,
                            lastname: lastnameInput,
                            email: emailInput,
                            contact_phone: phoneInput,
                            create_user: authUser.profileObj.email,
                            modify_user: authUser.profileObj.email,
                            fk_ctx_security_role: securityRoleSelect

                        }
                    }
                });
            }

            await refetch();
            handleClose();
            showInfoToast("Partner succesvol verwerkt");
        }catch(err){
            //TODO: alert? wtf
            showErrorToast("Er ging iets mis bij het uitvoeren van de partner actie.");
            handleClose();
        }
    }

    const valueChecker=()=>{
        return !!emailInput&&emailInput!="" && !!firstnameInput&&firstnameInput!="" && !!lastnameInput&&lastnameInput!="" && !!phoneInput&&phoneInput!="" && !!authUser&&authUser.profileObj&&authUser.profileObj.email;
    }

    const getSelectedSecurityRoleName=(id)=>{
        const res = securityRoles.find((role)=>{
            return role.id==id;
        });
        return res?res.name:"";
    }

    return(
        <>
        <Dialog open={showDetailDialog} onClose={handleClose} maxWidth="md" fullWidth={true}>
                    <DialogTitle>Werknemer details</DialogTitle>
                    <Divider style={{width: "95%", margin: "auto", marginBottom: 15}} />
                    <DialogContent >
                        <div style={{width: "90%", margin: "auto"}}>
                            <TextField disabled={true} label="Werknemer Id" style={{width: "40%", float: "left", padding: 5}} value={selectedEmployee?selectedEmployee.employee_id:"/"} />
                            <TextField label="E-Mail" style={{width: "40%", float: "left", padding: 5}} defaultValue={emailInput} onChange={(e)=>setEmailInput(e.target.value)} />
                            <TextField label="Voornaam" style={{width: "40%", float: "left", padding: 5, marginTop: 50}} defaultValue={firstnameInput} onChange={(e)=>setFirstnameInput(e.target.value)} />
                            <TextField label="Achternaam" style={{width: "40%", float: "left", padding: 5, marginTop: 50}} defaultValue={lastnameInput} onChange={(e)=>setLastnameInput(e.target.value)} />
                            <TextField label="GSM" style={{width: "40%", float: "left", padding: 5, marginTop: 50}} defaultValue={phoneInput} onChange={(e)=>setPhoneInput(e.target.value)} />
                            <Autocomplete
                            options={securityRoles?securityRoles:[]}
                            getOptionLabel={(option) => option.name}
                            style={{ width: "40%", float: "left", padding: 5, marginTop: 50 }}
                            value={securityRoleSelect}
                            onChange={(e, value)=>setSecurityRoleSelect(value?value.id:null)}
                            inputValue={securityRoleSearchString}
                            onInputChange={(e, val)=>{
                                if(!!e)
                                setSecurityRoleSearchString(val);
                            }}
                            renderInput={(params) => <TextField {...params} label="Security Role" variant="outlined" />}
                            />
                        </div>
                    </DialogContent>
                    <br style={{marginBottom: 15, marginTop: 15}} />
                    <DialogActions>
                        <Button color="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        <Button color="primary" disabled={!hasValues} onClick={handleConfirm}>
                            {!!selectedEmployee?"Bewaren":"Toevoegen"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                </>
    );
}

export default EmployeeDetail;