import { gql } from '@apollo/client';

export const QUERIES = {
    PROJECT_OVERVIEW: gql`
    query 
        AtnProjects($fiscalYear: String) {
        atnProjects(fiscalYear: $fiscalYear) {
            id
            name
            bestekken_project_id
            project_id
            create_date
            modify_date
            owner
            folder_id
            partner {
                id
                name
            }
            fiscalYear {
                id
                name
            }
            works {
                id
            }
        },
        partners {
            id
            name
        },
        fiscalYears {
            id
            name
        },
        employees {
            email
        }
        # googleUsers {
        #     email
        # }
    }

    `,
    WORK_OVERVIEW: gql`
    query Works($projectId: ID, $fiscalYear: String) {
        atnWorks(projectId: $projectId, fiscalYear: $fiscalYear) {
            id
            name
            estimate_worth
            estimate_date
            estimate_id
            work_id
            owner
            doc_id
            create_date
            modify_date
            apk_claim_id
            ticketLogs {
                id
            }
            projectType {
                name
            }
            workStatus {
                id
                name
            }
            project {
                id
                project_id
                folder_id
                partner {
                        id
                        name
                    }

            }
            projectType {
                id
                name
            }

        }
        atnProject(id: $projectId) {
            id
            project_id
        }
        workStatuses {
            id
            name
        }
        projectTypes {
            id
            name
        }
        employees {
            email
        }
        partners {
            id
            name
        }
    }
    `,

    PROJECT_DETAILS: gql`
    query ProjectDetails($id: ID) {
        atnProject(id: $id) {
            id
            project_id
            name
            owner
            bestekken_project_id
            partner {
                id
                name
            }
            works {
                id
                work_id
                name
            }
            fiscalYear {
                id
                name
            }
        }
        fiscalYears {
            id
            name
        }
        partners {
            id
            name
        }
        employees {
            email
        }
        bestekkenProjects {
            _id
            name
        }
    }
    `,

    FISCAL_YEARS: gql`
    query {
        fiscalYears {
        id
        name
        start_date
        end_date
        folder_id
    }
}
    `,
    CREATE_FISCAL_YEAR: gql`
    mutation CreateFiscalYear($fiscalYear: FiscalYearInput!) {
        createFiscalYear(fiscalYear: $fiscalYear) {
            id
            name
            start_date
            end_date
            create_user
        }
    }
    `,
    UPDATE_FISCAL_YEAR: gql`
    mutation UpdateFiscalYear($fiscalYear: FiscalYearInput!) {
        updateFiscalYear(fiscalYear: $fiscalYear) {
            id
            name
            start_date
            end_date
            create_user
        }
    }
    `,
    SECURITYROLES: gql`
    query {
        securityRoles {
        id
        name
        code
        create_user
    }
}
    `,

    WORK_DETAILS: gql`
        query WorkDetails($id: ID, $fiscalYear: String) {
            atnWork(id: $id) {
                id
                name
                owner
                work_id
                comment
                estimate_worth
                estimate_date
                estimate_id
                apk_profit_center_id
                workStatus {
                    id
                    name
                }
                project {
                    id
                    name
                    bestekken_project_id
                    partner{
                        name
                        api_client_id
                      }
                }
                projectType {
                    id
                    name
                }
            }
            employees {
                id
                email
        }
        atnProjects(fiscalYear: $fiscalYear) {
            id 
            name
            bestekken_project_id
        }
        projectTypes {
            id
            name
        }
        workStatuses {
            id
            name
        }
        }
    `,

    CREATE_PROJECT: gql`
    mutation CreateATNProject($project: ATNProjectInput!) {
        createATNProject(atnProject: $project) {
            id
            name
            project_id
            create_date
            modify_date
            owner
            folder_id
            bestekken_project_id
            partner {
                id
                name
            }
            fiscalYear {
                id
                name
            }
            works {
                id
            }
        }
    }
    `,
    UPDATE_PROJECT: gql`
    mutation UpdateATNProject($project: ATNProjectInput!) {
        updateATNProject(atnProject: $project) {
            id
            name
            project_id
            create_date
            modify_date
            owner
            folder_id
            bestekken_project_id
            partner {
                id
                name
            }
            fiscalYear {
                id
                name
            }
            works {
                id
            }
        }
    }
    `,
    CREATE_WORK: gql`
        mutation CreateWork($work: ATNWorkInput!) {
            createATNWork(atnWork: $work) {
                id
                name
                owner
                estimate_date
                fk_ctx_project
                apk_profit_center_id
                apk_billing_id
                apk_claim_id
            }
        }
    `,
    UPDATE_WORK: gql`
        mutation UpdateWork($work: ATNWorkInput!, $createEstimate: Boolean) {
            updateATNWork(atnWork: $work, createEstimate: $createEstimate) {
                id
                name
                owner
                estimate_date
                apk_profit_center_id
                apk_billing_id
                apk_claim_id
            }
        }
    `,
    PARTNERS: gql`
        query Partners {
            partners {
                id
                partner_id
                name
                address
                api_client_id
            }
        }
    `,
    CREATE_PARTNER: gql`
        mutation CreatePartner($partner: PartnerInput!) {
            createPartner(partner: $partner) {
                id
                partner_id
                name
                address
            }
        }
    `,
    UPDATE_PARTNER: gql`
        mutation UpdatePartner($partner: PartnerInput!) {
            updatePartner(partner: $partner) {
                id
                partner_id
                name
                address
            }
        }
    `,
    EMPLOYEES: gql`
        query Employees {
            employees {
                id
                employee_id
                firstname
                lastname
                email
                contact_phone
                fk_ctx_security_role
                securityRole{
                    id
                    name
                }
            }
        }
    `,

EMPLOYEES_BY_EMAIL: gql`
query EmployeeByMail($email: String!) {
    employeeByMail(email: $email) {
        id
        employee_id
        firstname
        lastname
        email
        contact_phone
        fk_ctx_security_role
        securityRole{
            id
            name
        }
    }
}
`,
    CREATE_EMPLOYEE: gql`
        mutation CreateEmployee($employee: EmployeeInput!) {
            createEmployee(employee: $employee) {
                id
                employee_id
                firstname
                lastname
                email
                contact_phone
                fk_ctx_security_role
            }
        }
    `,
    UPDATE_EMPLOYEE: gql`
        mutation UpdateEmployee($employee: EmployeeInput!) {
            updateEmployee(employee: $employee) {
                id
                employee_id
                firstname
                lastname
                email
                contact_phone
                fk_ctx_security_role
            }
        }
    `,
    BESTEKKEN_CLIENTS: gql`
        query BestekkenClients {
            bestekkenClients {
                clientId
                name
            }
        }
    `,
    BESTEKKEN_PROFITCENTERS: gql`
        query BestekkenProfitcenters($projectId: String!) {
            bestekkenProfitcenters(projectId: $projectId) {
                _id
                name 
                department 
                company 
            }
        }
    `,
    SEND_TO_BESTEKKEN: gql`
    mutation SendToBestekken($bestekkenInput: SendToBestekken!) {
        sendToBestekken(inputWork: $bestekkenInput) {
            billing_id
            status
            error
            message
            apk_claim_id
        }
    }
    `,
    EMPLOYEE_BY_MAIL: gql`
        query employeeByMail($email: String!) {
            employeeByMail(email: $email) {
                id
                email
                fk_ctx_security_role
            }
        }
    `,
    TICKETLOGS_BY_WORK: gql`
        query atnTicketLogs($workId: ID, $filter: ATNTicketLogFilter) {
            atnTicketLogs(workId: $workId, filter: $filter) {
                id
                ticket_number
                subject
                create_date
                flag_processed
                doc_id
                work {
                    id
                    name
                    project {
                        id
                        name
                    }
                }
            }
        }
    `,
    TICKETLOG_MODAL: gql`
    query atnWork($id: ID) {
        atnWork(id: $id) {
            open_tickets
            closed_tickets
            work_duration
            work_balance
            material_balance
        }
    }
    `,
    UPDATE_TICKETLOG: gql`
        mutation updateATNTicketLog($ticketLog: ATNTicketLogInput!) {
            updateATNTicketLog(atnTicketLog: $ticketLog) {
                id
            }
        }
    `
}