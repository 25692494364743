import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import {
    Button, CircularProgress, DialogActions, DialogContent,
    DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { QUERIES } from '../../../../api/queries';
import { gql, useMutation, useQuery } from '@apollo/client';

const ProjectDetails = props => {

    const [authUser, setAuthUser] = useGlobal('authUser');
    const [createProject] = useMutation(QUERIES.CREATE_PROJECT);
    const [updateProject] = useMutation(QUERIES.UPDATE_PROJECT);
    const [working, setWorking] = useState(false)

    const projectModel = {
        id: null,
        project_id: '',
        fk_ctx_partner: null,
        fk_ctx_fiscalyear: null,
        owner: null,
        fk_ctx_sla: '2',
        bestekken_project_id: '10416'
    }

    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedPartner, setSelectedPartner] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedBestekkenProject, setSelectedBestekkenProject] = useState(null);
    const { data, error, loading } = useQuery(QUERIES.PROJECT_DETAILS, { variables: { id: props.projectId } })
    const [currentProject, setCurrentProject] = useState({ ...projectModel })

    useEffect(() => {
        if (!!data) {
            if (!data.atnProject) {
                setSelectedFiscalYear(data.fiscalYears.find(el => el.name == new Date().getFullYear().toString()))
                setSelectedUser({ email: authUser.getBasicProfile().getEmail() })
                setSelectedBestekkenProject(data.bestekkenProjects.find(el => el._id === projectModel.bestekken_project_id));
                // console.log(authUser.getBasicProfile().getEmail())
            }
            else {
                console.log(data);
                console.log(data.atnProject)
                setSelectedFiscalYear(data.atnProject.fiscalYear)
                setSelectedUser({ email: data.atnProject.owner })
                setSelectedPartner(data.atnProject.partner)
                console.log(data.bestekkenProjects.find(el => el._id === data.atnProject.bestekken_project_id));
                setSelectedBestekkenProject(data.bestekkenProjects.find(el => el._id === data.atnProject.bestekken_project_id));
                setCurrentProject(old => ({
                    ...old,
                    name: data.atnProject.name,
                    id: data.atnProject.id,
                    project_id: data.atnProject.project_id
                }))
            }
        }
    }, [data]);

    useEffect(() => {
        console.log('current project: ', currentProject);
    }, [currentProject]);

    useEffect(() => {
        if (selectedUser) {
            setCurrentProject(old => ({ ...old, owner: selectedUser.email }))
        }
        if (selectedPartner) {
            setCurrentProject(old => ({ ...old, fk_ctx_partner: selectedPartner.id }))
        }
        if (selectedFiscalYear) {
            setCurrentProject(old => ({ ...old, fk_ctx_fiscalyear: selectedFiscalYear.id }))
        }
        setCurrentProject(old => ({...old, bestekken_project_id: selectedBestekkenProject? selectedBestekkenProject._id: null}))
    }, [selectedUser, selectedFiscalYear, selectedPartner, selectedBestekkenProject]);

    if (working) {
        return (<DialogContent>
            <CircularProgress/>
        </DialogContent>)
    }

    return (
        // <Dialog
        //     open={props.visible}
        //     onEnter={() => {

        //     }}
        //     onExited={() => {
        //         console.log('now closed')
        //         setCurrentProject({ ...projectModel })
        //         setSelectedFiscalYear(data.fiscalYears.find(el => el.name == new Date().getFullYear().toString()))
        //         setSelectedUser({ email: authUser.getBasicProfile().getEmail() })
        //         setSelectedPartner(null);
        //     }}
        //     keepMounted={false}>
        !!data && (
            <>
                <>
                    <DialogTitle id="form-dialog-title">{data.atnProject ? ('Project ' + data.atnProject.project_id) : 'Nieuw Project'}</DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
          </DialogContentText> */}
                        {/* <FormControl>
                    <InputLabel>Boekjaar</InputLabel>
                    <Select
                        value={selectedFiscalYear}
                        onChange={e => { setSelectedFiscalYear(e.target.value) }}>
                        {data.fiscalYears.map(el => (<MenuItem value={el}>{el.name | 'alle'}</MenuItem>))}
                    </Select>
                </FormControl> */}
                        <Autocomplete
                            options={data.fiscalYears}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 180, margin: 8 }}
                            value={selectedFiscalYear}
                            onChange={(event, newValue) => {
                                setSelectedFiscalYear(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} label="Boekjaar" variant="outlined" />}
                        />
                        <Autocomplete
                            options={data.partners}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 360, margin: 8 }}
                            value={selectedPartner}
                            onChange={(event, newValue) => {
                                if (!!newValue) {
                                    setSelectedPartner(newValue)
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Klant" variant="outlined" />}
                        />
                        <Autocomplete
                            options={data.employees}
                            getOptionLabel={(option) => option.email? option.email: ''}
                            style={{ width: 360, margin: 8 }}
                            value={selectedUser}
                            onChange={(event, newValue) => {
                                if (!!newValue) {
                                    setSelectedUser(newValue)
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Eigenaar" variant="outlined" />}
                        />
                        <Autocomplete
                            options={data.bestekkenProjects}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 360, margin: 8 }}
                            value={selectedBestekkenProject}
                            onChange={(event, newValue) => {
                                    setSelectedBestekkenProject(newValue? newValue: {_id: '10416', name: 'ATN Divers'})
                            }}
                            renderInput={(params) => <TextField {...params} label="Bestekken Project" variant="outlined" />}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Naam"
                            fullWidth
                            value={currentProject.name}
                            onChange={(e) => {
                                console.log(e.target.value);
                                setCurrentProject(old => ({ ...old, name: e.target.value }))
                            }}
                        />
                    </DialogContent>
                </>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Annuleren
          </Button>
                    <Button onClick={async () => {
                        if (!props.projectId) {
                            setWorking(true);
                            try {
                                const mutationRes = await createProject({
                                    variables: {
                                        project: {
                                            ...currentProject,
                                            create_user: authUser.getBasicProfile().getEmail(),
                                            modify_user: authUser.getBasicProfile().getEmail(),
                                        }
                                    },
                                    // update: (cache, { data: { createATNProject } }) => {
                                    //     const cacheData = { ...cache.readQuery({ query: QUERIES.PROJECT_OVERVIEW }) };
                                    //     cacheData.atnProjects = [...cacheData.atnProjects, createATNProject];
                                    //     cache.writeQuery({ query: QUERIES.PROJECT_OVERVIEW, data: cacheData });
                                    // }
                                });
                                props.handleSave();
                            }
                            catch (err) {
                                console.error('mutation error: ', err);
                                setWorking(false);
                            }
                        }
                        else {
                            try {
                                setWorking(true);
                                console.log('project update');
                                const updateRes = await updateProject({
                                    variables: {project: {
                                        ...currentProject, 
                                        modify_user: authUser.getBasicProfile().getEmail(),
                                    }},
                                    // update: (cache, { data: { updateATNProject } }) => {
                                    //     const cacheData = { ...cache.readQuery({ query: QUERIES.PROJECT_OVERVIEW }) };
                                    //     // cacheData.atnProjects = [...cacheData.atnProjects, createATNProject];
                                    //     const newProjects = cacheData.atnProjects.map(el => el.id === updateATNProject.id? updateATNProject: el);
                                    //     console.log('newProjects: ', newProjects)
                                    //     cache.writeQuery({ query: QUERIES.PROJECT_OVERVIEW, data: {...cacheData, atnProjects: newProjects} });
                                    // }
                                });
                                props.handleSave();
                            }
                            catch (err) {
                                setWorking(false);
                                console.error('mutation error: ', err);
                            }
                        }
                    }} color="primary">
                        Opslaan
          </Button>
                </DialogActions>
            </>
        )
        // </Dialog>
    );
};
export default ProjectDetails;