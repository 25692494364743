import React, { useEffect } from 'react';
import { useGlobal } from 'reactn';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';
import { Card, Grid, CardContent, Typography } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { QUERIES } from '../../api/queries';


const Login = props => {

    const [authUser, setAuthUser] = useGlobal('authUser');
    const [currentUser, setCurrentUser] = useGlobal('currentUser');
    const [requestedRoute, setRequestedRoute] = useGlobal('requestedRoute');
    const [client, setClient] = useGlobal('apolloClient')
    const [loadUser, { called, loading, data }] = useLazyQuery(QUERIES.EMPLOYEE_BY_MAIL);

    useEffect(() => {
        if (!!authUser && !!client) {
            // console.log('authuser: ', authUser);
            loadUser({variables: {email: authUser.getBasicProfile().getEmail()}})
        }
    }, [authUser, client]);

    useEffect(() => {
        if (!!data) {
            // console.log(data);
            if (!!data.employeeByMail) {
                // console.log(data.employeeByMail);
                setCurrentUser({...data.employeeByMail});
            }
        }
    }, [data])

    useEffect(() => {
        if (!!currentUser) {
            console.log(currentUser)
            if (currentUser.fk_ctx_security_role === "1" || currentUser.fk_ctx_security_role === "2") {
                props.history.replace(requestedRoute? requestedRoute.pathname: '/');
            }
        }
    }, [currentUser])

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Card
                variant="outlined">
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Inloggen met je Ageres-account
        </Typography>
                    </CardContent>
                    <GoogleLogin
                        // responseType="code"
                        className="googleButton"
                        clientId="944762034876-hs398hn6do3e01uh4ih1upvk24mf9iq0.apps.googleusercontent.com"
                        buttonText="Login"
                        // approvalPrompt="force"
                        // prompt='consent'
                        // offline={true}
                        onSuccess={async (responseGoogle) => {
                            console.log('login success');
                            setAuthUser(responseGoogle);
                            // <Redirect to={"/"} />
                            // console.log(history)
                        }}
                        onFailure={(responseGoogle) => console.log(responseGoogle)}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                        // scope='https://www.googleapis.com/auth/admin.directory.group'
                        scope='https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/gmail.compose'
                    />
                </Grid>
            </Card>
        </Grid>)
}

export default Login;