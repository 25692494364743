import React from 'react';
import SecurityRoles from './SettingComponents/SecurityRoles'
import FiscalYear from './SettingComponents/FiscalYear'
const divStyle = {
    margin: "1em auto",

  };
function SettingsPage() {
  return (
      <div style={divStyle}>
          <SecurityRoles/>
          <FiscalYear/>
      </div>
  )
}
 

 
export default SettingsPage;