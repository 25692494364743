import React, {useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import WorkIcon from '@material-ui/icons/Work';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FaceIcon from '@material-ui/icons/Face';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

const AtnDrawer = ({ showDrawer, toggleDrawer }) => {
    const classes = useStyles();
    const history = useHistory();
    const [authUser, setAuthUser] = useGlobal('authUser');
    const [settingsOpen, setSettingsOpen] = useState(false);

    const handleHistory=(path)=>{
      history.push(path);
      toggleDrawer(false);
    }

    return(
        <React.Fragment key={"left"}>
        <Drawer anchor={"left"} open={showDrawer} onClose={()=>toggleDrawer(false)}>
          <div style={{width: 312, height: 168, backgroundPosition: "center", color: "white", backgroundColor: "grey" }}>
            <div style={{float: "left"}}>
            {authUser&&authUser.profileObj&&
                <>
                <p style={{margin: 15, fontSize: 22, marginTop: 80 }}>{authUser.profileObj.familyName}</p>
                <p style={{margin: 15, fontSize: 15}}>{authUser.profileObj.email}</p>
                </>
            }
            </div>
            <IconButton style={{float: "right", margin: 5}} edge="start" color="inherit" aria-label="menu" onClick={()=>toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <List className={classes.root}>
            <ListItem button key={"Werken"}>
              <ListItemIcon><WorkIcon style={{color: "#404040"}} /></ListItemIcon>
              <ListItemText primary={"Werken"} onClick={()=>handleHistory("/works")} />
            </ListItem>
            <ListItem button key={"Projecten"}>
              <ListItemIcon><ListAltIcon style={{color: "#404040"}} /></ListItemIcon>
              <ListItemText primary={"Projecten"} onClick={()=>handleHistory("/")} />
            </ListItem>
            <ListItem button key={"Instellingen"} onClick={()=>setSettingsOpen(!settingsOpen)}>
                        <ListItemIcon><SettingsIcon style={{color: "#404040"}} /></ListItemIcon>
                        <ListItemText primary={"Instellingen"} />
                        {settingsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button key={"Klanten"} className={classes.nested}>
                        <ListItemIcon><FaceIcon style={{color: "#404040"}} /></ListItemIcon>
                        <ListItemText primary={"Klanten"} onClick={()=>handleHistory("/customers")} />
                    </ListItem>
                    <ListItem button key={"Werknemers"} className={classes.nested}>
                        <ListItemIcon><FaceIcon style={{color: "#404040"}} /></ListItemIcon>
                        <ListItemText primary={"Werknemers"} onClick={()=>handleHistory("/employees")} />
                    </ListItem>
                    <ListItem button key={"Wverig"} className={classes.nested}>
                        <ListItemIcon><SettingsIcon style={{color: "#404040"}} /></ListItemIcon>
                        <ListItemText primary={"Overig"} onClick={()=>handleHistory("/settings")} />
                    </ListItem>
                </List>
            </Collapse>
          </List>
        </Drawer>
      </React.Fragment>
    );
}

export default AtnDrawer;