import './App.css';
import {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
// import {InMemoryCache} from 'apollo-boost';
import PrivateRoute from './auth/PrivateRoute';
import Login from './components/Login/Login'
import ProjectOverview from './components/Project/ProjectOverview/ProjectOverview';
import HeaderMenu from './components/Navigation/HeaderMenu';
import { useGlobal } from 'reactn';
import WorkOverview from './components/Work/WorkOverview/WorkOverview';
import Customers from './components/Customers/Customers';
import SettingsPage from './components/Settings/SettingsPage';
import Employees from './components/Employees/Employees';
import TicketLogOverview from './components/TicketLogs/TicketLogOverview/TicketLogOverview';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = ()=>  {

  const [authUser, setAuthUser] = useGlobal('authUser');
  const [client, setClient] = useGlobal('apolloClient');

  useEffect(() => {
    console.log(process.env.NODE_ENV);
    let httpLink = createHttpLink({
      // uri: 'https://api-33k7vpwdbq-ew.a.run.app/',
      // uri: 'http://localhost:8080',
      uri: process.env.NODE_ENV === 'development'? 'http://localhost:8080': 'https://api-33k7vpwdbq-ew.a.run.app/',
      // credentials: 'same-origin',
      headers: {
        'authorization': authUser? authUser.tokenId: '',
        'accessToken': authUser? authUser.accessToken: ''
      }
    });

    let client = new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink
    });

    setClient(client);
  }, [authUser]);

  if (!client) {
    return null;
  }
  
  return (
    <ApolloProvider client={client}>
    <Router>
      <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-start"
      style={{overflowX:'hidden'}}>
      
        <HeaderMenu/>
        <Switch>
        <Route path='/login' exact component={Login}/>
        <PrivateRoute path='/' exact component={ProjectOverview}/>
        <PrivateRoute path='/works' exact component={WorkOverview}/>
        <PrivateRoute path='/works/:projectId' exact component={WorkOverview}/>
        <PrivateRoute path='/customers' exact component={Customers}/>
        <PrivateRoute path='/employees' exact component={Employees}/>
        <PrivateRoute path='/settings' exact component={SettingsPage}/>
        <PrivateRoute path='/ticketlogs/:workId' exact component={TicketLogOverview}/>
      </Switch>
      </Grid>

      <ToastContainer />
      </Router>
      </ApolloProvider>
  );
}

export default App;
