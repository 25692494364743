import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, IconButton, CircularProgress, Button, Dialog, Icon } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudIcon from '@material-ui/icons/Cloud';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DataGrid } from '@material-ui/data-grid';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERIES } from '../../../api/queries';
import { showInfoToast } from '../../../util/toasts';

const sheetBaseUrl = 'https://docs.google.com/spreadsheets/d/';

const TicketLogOverview = props => {

    const [ticketLogs, setTicketLogs] = useState(null);
    const [filteredTicketLogs, setFilteredTicketLogs] = useState(null);
    const [updateTicketLog] = useMutation(QUERIES.UPDATE_TICKETLOG);
    const [queryVars, setQueryVars] = useState({
        variables: {
            workId: props.match.params.workId,
            filter: {
                flag_processed: null,
                work_name: null
            }
        }
    });
    const flagFilterOptions = [{tag: "Alles", value: null}, {tag: "Ja", value: 1}, {tag: "Nee", value: 0}];
    const [filterIsExpanded, setFilterisExpanded] = useState(false);
    const [workNameHodler, setWorkNameHolder] = useState("");

    const [loadTicketLogs, { called, loading, data, refetch }] = useLazyQuery(QUERIES.TICKETLOGS_BY_WORK, {fetchPolicy: 'network-only'});

    const filterTicketLogs = () => {
        let res = [...ticketLogs];

        // Insert logic here
        setFilteredTicketLogs(res);
    }

    useEffect(() => {
        if (!!props.match.params.workId) {
            loadTicketLogs(queryVars)
        }
    }, [queryVars]);

    useEffect(() => {
        if (!!data) {
            if (data.atnTicketLogs) {
                setTicketLogs(data.atnTicketLogs);
            }
        }
    }, [data]);

    useEffect(() => {
        if (!!ticketLogs && Array.isArray(ticketLogs))
        filterTicketLogs();
    }, [ticketLogs]);
    
    const cols = [
        {
            field: 'id', headerName: 'ID', renderCell: (params) => (
               <b>{params.value}</b>
            )
        },
        { field: 'ticket_number', headerName: 'Ticket n°' },
        {
            field: 'work', headerName: 'Werk', width: 220, valueGetter: (params) => params.getValue('work').name || '-',
            sortComparator: (v1, v2, row1, row2) => {
                if (row1.data.work.name.toLowerCase().trim() > row2.data.work.name.toLowerCase().trim()) {
                    return 1;
                }
                if (row2.data.work.name.toLowerCase().trim() > row1.data.work.name.toLowerCase().trim()) {
                    return -1;
                }
                return 0
            }
        },
        {
            field: 'project', headerName: 'Project', width: 200, valueGetter: (params) => params.getValue('work').project.name || '-',
            sortComparator: (v1, v2, row1, row2) => {
                if (row1.data.work.project.name.toLowerCase().trim() > row2.data.work.project.name.toLowerCase().trim()) {
                    return 1;
                }
                if (row2.data.work.project.name.toLowerCase().trim() > row1.data.work.project.name.toLowerCase().trim()) {
                    return -1;
                }
                return 0
            }
        },
        { field: 'subject', headerName: 'Onderwerp', width: 360 },
        { field: 'create_date', headerName: 'Ontvangstdatum', width: 150, renderCell: (params) => {
            return (new Date(params.value).toLocaleString('nl-be'))
        }},
        { field: 'flag_processed', headerName: 'Verwerkt', width: 90, renderCell: (params) => {
            return (!!params.value? (<CheckIcon/>) : (<></>))
        }},
        { field: 'doc_id', headerName: 'TicketLog', width: 100, renderCell: (params) => {
            return (params.value ? <a href={sheetBaseUrl + params.value} target='_blank'><DescriptionIcon /></a> : <></>)
        }},
        {field: '', headerName: '', width: 300, renderCell: (params) => {
            return(<Button
                onClick={() => {
                    console.log(params)
                    updateTicketLog({variables: {ticketLog: {id: params.data.id, fk_ctx_work: null}}}).then(res => {
                        console.log(res);
                        refetch();   
                        showInfoToast(`Ticket ${params.data.ticket_number} ontkoppeld`);
                    })
                }}
            >Ontkoppel van werk</Button>)
        }}
    ];

   return (
    <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    spacing={4}>
        <Grid item>
                <Typography variant='h2'>{props.match.params.workId? `Werk ${props.match.params.workId}/ Ticketlogs`: `TicketLogs`}</Typography>
                </Grid>
       {(filteredTicketLogs != null && !loading)? (<Grid item>
        <Accordion style={{margin: "0px 75px 25px"}} expanded={filterIsExpanded} onChange={(evt, expanded) => {
            setFilterisExpanded(expanded);
            console.log("expandddd", expanded);
            }}>
            <AccordionSummary
                expandIcon={<FilterListIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography >Filter</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Autocomplete
                    options={flagFilterOptions}
                    getOptionLabel={(option) => option.tag}
                    style={{ width: 200, margin: 8 }}
                    value={flagFilterOptions.find((option) => option.value == queryVars.variables.filter.flag_processed)}
                    onChange={(event, newValue) => {
                        setQueryVars(old => {
                            let tempVars = {...old};
                            tempVars.variables.filter.flag_processed = newValue != null &&newValue.value != null? newValue.value: null;
                            return tempVars;
                        });
                    }}
                    renderInput={(params) => <TextField {...params} label="Verwerkt" variant="outlined" />}
                />
                <TextField
                label="Werk"
                variant="outlined"
                value={workNameHodler}
                onBlur={(evt)=> {
                    setQueryVars(old => {
                    let tempVars = {...old};
                    tempVars.variables.filter.work_name = evt.target.value;
                    return tempVars;
                    });
                }}
                onChange={(evt)=> setWorkNameHolder(evt.target.value)}
                style={{marginTop: 8, marginLeft: 15}}
                />
            </AccordionDetails>
        </Accordion>

    <div style={{ height: '80vh', width: '90vw' }}>
        <DataGrid key={(filteredTicketLogs && filteredTicketLogs.length > 0) ? filteredTicketLogs : []} 
        columns={cols} 
        rows={[...filteredTicketLogs]} 
        // sortModel={[{ field: 'modify_date', sort: 'desc' }]}
        ></DataGrid>
    </div>
</Grid>): (<CircularProgress />)}
</Grid>
)
}

export default TicketLogOverview;