import React, { useEffect, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useQuery } from '@apollo/client';
import { QUERIES } from '../../../api/queries';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import FiscalYearDetail from './FiscalYearDetail/FiscalYearDetail';

const divStyle = {
    margin: "1em",
    minWidth: "100%",
    minHeight: "400px", 
    borderTop: "1px solid #cccccc",
    padding: "10px",
  };
function FiscalYear() {
    const [fiscalYear, setFiscalYear] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [showDetailDialog, setShowDetailDialog] = useState(false);

    const cols = [
        { field: 'name', headerName: 'Naam', width: 250 },
        { field: 'start_date', headerName: 'Startdatum', width: 250, renderCell: ( {data} )=>{
            let startdate = new Date(data.start_date.split('T')[0]).toLocaleDateString('nl-BE');
                return(
                    <p>{startdate}</p>
                )  
        } },
        { field: 'end_date', headerName: 'Einddatum', width: 250, renderCell: ( {data} )=>{
            let enddate = new Date(data.end_date.split('T')[0]).toLocaleDateString('nl-BE');
                return(
                    <p>{enddate}</p>
                )  
        } },
        { field: 'folder_id', headerName: 'Google Drive map', width: 150, renderCell: ( {data} )=>{
            if (data.folder_id && data.folder_id != '-'){
                return(
                    <Button variant="contained" color="primary" href={'https://drive.google.com/drive/folders/'+ data.folder_id } target="_blank">
                        <FolderIcon/>
                    </Button>
                )
            }
            
        } },  
    //     { field: 'id', headerName: 'Bewerken', renderCell: ( {data} )=>{
    //         return (
    //             <IconButton
    //             disabled={loading}
    //             onClick={() => {
    //                 setSelectedFiscalYear(data);
    //                 setShowDetailDialog(true);
    //             }}>
    //                 <EditIcon />
    //             </IconButton>
    //         );
    // } },
    ];

    const { loading, error, data, refetch } = useQuery(QUERIES.FISCAL_YEARS, {
        fetchPolicy: 'cache-first',
        variables: {  }
    });

    useEffect(()=>{
       console.log(data)
        if(data&&data.fiscalYears){
            setFiscalYear(data.fiscalYears);
        }

    },[data]);

    return (
        <div style={divStyle}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={1}
                >
                    <>
                        <Grid item>
                            <Typography variant='h5' style={{marginBottom: '0', textAlign: "center"}}>Boekjaar</Typography>
                        </Grid>
                        <Grid item>
                                <IconButton disabled={loading} onClick={() => {
                                     setSelectedFiscalYear(data);
                                     setShowDetailDialog(true);
                                }}>
                                    <AddIcon />
                                </IconButton>
                                </Grid>
                        <Grid item >
                            <div style={{ height: '50vh', width: '90vw' }}>
                                <DataGrid key={fiscalYear?fiscalYear:[]} columns={cols} rows={fiscalYear?fiscalYear:[]} sortModel={[{ field: 'name', sort: 'desc' }]}></DataGrid>
                            </div>
                        </Grid>
                    </>  
                <FiscalYearDetail
                    showDetailDialog={showDetailDialog}
                    setShowDetailDialog={setShowDetailDialog}
                    selectedFiscalYear={selectedFiscalYear}
                    refetch={refetch}
                />             
            </Grid>
        </div>
    );
}
 

 
export default FiscalYear;