import React, { useEffect, useState } from 'react';
import {
    Card, Grid, TextField, Typography, Button,
    IconButton, CircularProgress, Dialog, InputLabel, FormControl
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudIcon from '@material-ui/icons/Cloud';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DataGrid } from '@material-ui/data-grid';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERIES } from '../../../api/queries';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import WorkDetails from '../WorkDetails/WorkDetails';
import TicketLogModal from './TicketLogModal';

const sheetsBaseUrl = 'https://docs.google.com/spreadsheets/d/';
const driveUrlBase = 'https://drive.google.com/drive/folders/';

const WorkOverview = (props) => {

    const [projectId, setProjectId] = useState(null)
    const [works, setWorks] = useState(null);
    const [filteredWorks, setFilteredWorks] = useState(null)
    const [fiscalYears, setFiscalYears] = useState(null)
    const [selectedFiscalYear, setSelectedFiscalYear] = useState({ id: -1, name: null });
    const [workStatuses, setWorkstatuses] = useState(null);
    const [projectTypes, setProjectTypes] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [partners, setPartners] = useState({ name: '', id: -1 });
    const [workDetailsVisible, setWorkDetailsVisible] = useState(false)
    const [selectedWork, setSelectedWork] = useState(null);
    const [ticketLogModalOpen, setTicketLogModalOpen] = useState(false);

    const [updateWork] = useMutation(QUERIES.UPDATE_WORK);

    const [filter, setFilter] = useState({
        status: { id: -1, name: '' },
        projectType: { id: -1, name: '' },
        owner: { email: '' },
        partner: { name: '', id: -1 },
        name: '',
        work_id: ''
    });

    const filterWorks = () => {
        let res = [...works];
        for (let el of Object.keys(filter)) {
            if (el === 'status') {
                if (filter[el].id != -1) {
                    res = res.filter(rEl => {
                        return filter.status.id === rEl.workStatus.id
                    });
                }
            }
            if (el === 'projectType') {
                if (filter[el].id != -1) {
                    res = res.filter(rEl => {
                        return filter.projectType.id === rEl.projectType.id
                    });
                }
            }
            if (el === 'partner') {
                console.log('filter partner: ', filter[el])
                if (filter[el].id != -1) {
                    res = res.filter(rEl => {
                        return filter.partner.id === rEl.project.partner.id
                    });
                }
            }
            if (el === 'owner') {
                if (filter[el].email !== '') {
                    res = res.filter(rEl => {
                        return filter[el].email === rEl.owner
                    });
                }
            }
            if (el === 'name') {
                if (filter[el] !== '') {
                    res = res.filter(rEl => {
                        return filter[el] === rEl.name
                    });
                }
            }
            if (el === 'work_id') {
                if (filter[el] !== '') {
                    res = res.filter(rEl => {
                        return filter[el] === rEl.work_id
                    });
                }
            }
        }
        setFilteredWorks(res);
    }

    const cols = [
        {
            field: 'id', headerName: 'Bewerken', renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        console.log(params.data)
                        setSelectedWork(params.data);
                        setWorkDetailsVisible(true);
                    }}
                >
                    <EditIcon />
                </IconButton>
            )
        },
        {
            field: 'work_id', headerName: 'ID', width: 120,
            sortComparator: (v1, v2, row1, row2) => {
                if (row1.data.work_id > row2.data.work_id) {
                    return 1;
                }
                if (row2.data.work_id > row1.data.work_id) {
                    return -1;
                }
                return 0
            }
        },
        { field: 'name', headerName: 'Naam', width: 400, renderCell: ({ value }) => (<b>{value}</b>) },
        { field: 'apk_claim_id', headerName: 'Bestekken Claim ID', width: 180 },
        { field: 'estimate_date', valueFormatter: ({ value }) => new Date(value).toLocaleDateString('nl-BE'), headerName: 'Offertedatum', width: 130 },
        {
            field: 'doc_id', headerName: 'Offerte', width: 100, renderCell: ({ value, data }) => {
                return (value ? <a href={sheetsBaseUrl + value} target='_blank'><DescriptionIcon /></a> :
                    <IconButton onClick={() => {
                        updateWork({ variables: { work: { id: data.id }, createEstimate: true } })
                            .then(res => {
                                console.log(res);
                            })
                    }}><AddIcon /></IconButton>)
            }
        },
        {
            field: 'estimate_worth', headerName: 'Offertebedrag', width: 180, renderCell: ({ value }) => {
                return `€ ${value.toString().replace('.', ',')}`
            }
        },
        {
            field: 'partner', headerName: 'Klant', width: 180, renderCell: ({ data }) => {
                return data.project.partner.name
            }
        },
        {
            field: 'workStatus', headerName: 'Status', width: 180, renderCell: ({ value }) => (value.name),
            sortComparator: (v1, v3, row1, row2) => {
                if (row1.data.workStatus.name.toLowerCase().trim() > row2.data.workStatus.name.toLowerCase().trim()) {
                    return 1;
                }
                if (row2.data.workStatus.name.toLowerCase().trim() > row1.data.workStatus.name.toLowerCase().trim()) {
                    return -1;
                }
                return 0
            }
        },
        {
            field: 'projectType', headerName: 'Werk Type', width: 160, valueGetter: params => params.getValue('projectType').name || '-',
            sortComparator: (v1, v3, row1, row2) => {
                if (row1.data.projectType.name.toLowerCase().trim() > row2.data.projectType.name.toLowerCase().trim()) {
                    return 1;
                }
                if (row2.data.projectType.name.toLowerCase().trim() > row1.data.projectType.name.toLowerCase().trim()) {
                    return -1;
                }
                return 0
            }
        },
        { field: 'project', headerName: 'Project', width: 180, renderCell: ({ value }) => value.project_id },
        { field: 'create_date', valueFormatter: ({ value }) => new Date(value).toLocaleDateString('nl-BE'), headerName: 'Aangemaakt op', width: 130 },
        { field: 'modify_date', valueFormatter: ({ value }) => new Date(value).toLocaleDateString('nl-BE'), headerName: 'Gewijzigd op', width: 130 },
        { field: 'owner', headerName: 'Eigenaar', width: 200 },
        {
            key: 'project_folder', headerName: 'Projectmap', width: 120, renderCell: ({ value }) => {
                return value.folder_id ? <a href={driveUrlBase + value.folder_id} target='_blank'><FolderIcon /></a> : ''
            }
        },
        {
            field: 'ticket_logs', headerName: 'ticketLogs', renderCell: (params) => {
                console.log(params);
                return (!!params.data.name.match(/tickets/gmi) ? (<Button color="primary" variant="outlined"
                    // onClick={() => props.history.push(`/ticketLogs/${params.data.id}`)}//(<Redirect to={`/works/${params.data.id}`}/>)}
                    onClick={() => {
                        setTicketLogModalOpen(true);
                        setSelectedWork(params.data)
                    }}//(<Redirect to={`/works/${params.data.id}`}/>)}
                >{params.data.ticketLogs.length}</Button>) : (<></>))
            }
        }
    ]

    const [loadWorks, { called, loading, data, refetch }] = useLazyQuery(QUERIES.WORK_OVERVIEW);
    const [loadFiscalYears, { called: fyCalled, loading: fyLoading, data: fyData }] = useLazyQuery(QUERIES.FISCAL_YEARS);

    useEffect(() => {
        if (!!fyData) {
            setFiscalYears([...fyData.fiscalYears, { id: -1, name: null }])
            if (props.match.params.projectId) {
                setSelectedFiscalYear({ id: -1, name: null })
            }
            else {
                setSelectedFiscalYear(fyData.fiscalYears.find(el => el.name === new Date().getFullYear().toString()))
            }
        }
    }, [fyData]);

    useEffect(() => {
        if (!!data) {
            setWorks(data.atnWorks)
            setWorkstatuses([...data.workStatuses, { id: -1, name: '' }]);
            setProjectTypes([...data.projectTypes, { id: -1, name: '' }]);
            setEmployees([...data.employees, { email: '' }]);
            setPartners([...data.partners, { name: '', id: -1 }]);
        }
    }, [data]);

    useEffect(() => {
        if (!!works) setWorks(null);
        console.log('queryDetails: ', !!props.match.params.projectId, !!selectedFiscalYear.name);
        if (!!props.match.params.projectId || !!selectedFiscalYear.name) {
            loadWorks({ variables: { projectId: props.match.params.projectId || null, fiscalYear: selectedFiscalYear.name } })
        }
        else {
            console.log('Loading works without fiscalyear or project is blocked as too intensive');
        }
    }, [selectedFiscalYear])

    useEffect(() => {
        if (!!works) {
            filterWorks();
        }
    }, [works, filter])

    useEffect(() => {
        loadFiscalYears()
    }, []);
    return (
        <>
            {(!!ticketLogModalOpen && !!selectedWork) &&
                (<Dialog open={ticketLogModalOpen} >
                    <TicketLogModal work={selectedWork} history={props.history} onClose={() => {
                        setTicketLogModalOpen(false);
                        // setSelectedWork(null);
                    }}
                    onComplete={() => {
                        setTicketLogModalOpen(false);
                        setTimeout(() => {
                            refetch();
                        }, 3000)
                        // loadWorks({ variables: { projectId: props.match.params.projectId || null, fiscalYear: selectedFiscalYear.name } });
                    }}/>
                </Dialog>)
            }
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}>
                <Grid item>
                    <Typography variant='h2'>{data ? (data.atnProject ? `Project ${data.atnProject.project_id}/ Werken` : 'Werken') : 'Werken'}</Typography>
                    <Dialog
                        open={workDetailsVisible}
                        maxWidth={'lg'}
                        fullWidth={true}
                    >
                        <WorkDetails
                            visible={workDetailsVisible}
                            workId={selectedWork ? selectedWork.id : null}
                            fiscalYear={selectedFiscalYear ? selectedFiscalYear.name : null}
                            handleClose={() => setWorkDetailsVisible(false)}
                            handleSave={async () => {
                                setWorkDetailsVisible(false)
                                refetch().then(res => {
                                    console.log('Refetch res: ', res)
                                    // handleData(res.data);
                                })
                            }} />
                    </Dialog>
                </Grid>
                {(!!filteredWorks && !!workStatuses && !!works &&
                    !!projectTypes && !!employees && !loading) ? (
                    <>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                spacing={4}>
                                <Grid item>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<FilterListIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography >Filter</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {/* <FormControl>
                                    <InputLabel>Boekjaar</InputLabel>
                                    <Select 
                                    value={selectedFiscalYear}
                                    onChange={e => {setSelectedFiscalYear(e.target.value)}}>
                                        {fiscalYears.map(el => (<MenuItem value={el}>{el.name | 'alle'}</MenuItem>))}
                                    </Select>
                                </FormControl> */}
                                            {!props.match.params.projectId &&
                                                <Autocomplete
                                                    options={fiscalYears}
                                                    getOptionLabel={(option) => {
                                                        return option.name || 'Alle'
                                                    }}
                                                    style={{ width: 130, margin: 8 }}
                                                    value={selectedFiscalYear}
                                                    onChange={(event, newValue) => {
                                                        if (!!newValue) {
                                                            setSelectedFiscalYear(newValue);
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Boekjaar" variant="outlined" />}
                                                />}
                                            <Autocomplete
                                                options={works.map(el => el.work_id)}
                                                getOptionLabel={(option) => {
                                                    return option || ''
                                                }}
                                                style={{ width: 180, margin: 8 }}
                                                value={filter.work_id}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, work_id: newValue ? newValue : '' }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="ID" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={works.map(el => el.name)}
                                                getOptionLabel={(option) => {
                                                    return option || ''
                                                }}
                                                style={{ width: 180, margin: 8 }}
                                                value={filter.name}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, name: newValue ? newValue : '' }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Naam" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={workStatuses}
                                                getOptionLabel={(option) => {
                                                    return option.name || ''
                                                }}
                                                style={{ width: 180, margin: 8 }}
                                                value={filter.workStatus}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, status: newValue ? newValue : { id: '-1', name: '' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={projectTypes}
                                                getOptionLabel={(option) => {
                                                    return option.name || ''
                                                }}
                                                style={{ width: 180, margin: 8 }}
                                                value={filter.projectType}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, projectType: newValue ? newValue : { id: '-1', name: '' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Werk Type" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={employees}
                                                getOptionLabel={(option) => {
                                                    return option.email || ''
                                                }}
                                                style={{ width: 300, margin: 8 }}
                                                value={filter.owner}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, owner: newValue ? newValue : { email: '' } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Eigenaar" variant="outlined" />}
                                            />
                                            <Autocomplete
                                                options={partners}
                                                getOptionLabel={(option) => {
                                                    return option.name || ''
                                                }}
                                                style={{ width: 300, margin: 8 }}
                                                value={filter.partner}
                                                onChange={(event, newValue) => {
                                                    setFilter(old => ({ ...old, partner: newValue ? newValue : { name: '', id: -1 } }));
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Klant" variant="outlined" />}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => {
                                        setSelectedWork(null)
                                        setWorkDetailsVisible(true)
                                    }}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <div style={{ height: '80vh', width: '90vw' }}>
                                <DataGrid key={(filteredWorks && filteredWorks.length > 0) ? filteredWorks : []}
                                    columns={cols}
                                    rows={[...filteredWorks]}
                                    sortModel={[{ field: 'modify_date', sort: 'desc' }]}></DataGrid>
                            </div>
                        </Grid>
                    </>
                ) : (<CircularProgress />)}
            </Grid>
        </>
    );
}

export default WorkOverview;