import React, { useEffect, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useQuery } from '@apollo/client';
import { QUERIES } from '../../api/queries';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import CustomerDetail from './CustomerDetail/CustomerDetail';

const Customers = props => {

    const [customers, setCustomers] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [bestekkenClients, setBestekkenClients] = useState([]);

    const cols = [
        { field: 'partner_id', headerName: 'Klant Id', width: 150 },
        { field: 'name', headerName: 'Naam', width: 450 },
        { field: 'address', headerName: 'Adres', width: 450 },
        { field: 'id', headerName: 'Bewerken', renderCell: ( {data} )=>{
                return (
                    <IconButton
                    disabled={bestekkenLoading}
                    onClick={() => {
                        setSelectedCustomer(data);
                        setShowDetailDialog(true);
                    }}>
                        <EditIcon />
                    </IconButton>
                );
        } },
    ];

    const { loading, error, data, refetch } = useQuery(QUERIES.PARTNERS, {
        fetchPolicy: 'cache-first',
        variables: {  }
    });

    const { loading:bestekkenLoading, error:bestekkenError, data:bestekkenData, refetch:bestekkenRefetch } = useQuery(QUERIES.BESTEKKEN_CLIENTS, {
        fetchPolicy: 'cache-first',
        variables: {  }
    });

    useEffect(()=>{
        if(data&&data.partners)
            setCustomers(data.partners);
    },[data]);

    useEffect(()=>{
        handleFilter(searchString);
    },[customers]);

    useEffect(()=>{
        if(bestekkenData&&bestekkenData.bestekkenClients)
            setBestekkenClients(bestekkenData.bestekkenClients);
    },[bestekkenData]);


    const handleFilter=(value)=>{
        value=value?value.toLowerCase():"";
        setSearchString(value);
        let customersCopy = [...customers];
        customersCopy=customersCopy.filter((customer)=>{
            const name = (customer&&customer.name?customer.name:"").toLowerCase();
            const address = (customer&&customer.address?customer.address:"").toLowerCase();
            return name.includes(value) || address.includes(value);
        });
        setFilteredCustomers(customersCopy);
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}>

                <>
                    <Grid item>
                        <Typography variant='h2' style={{marginBottom: 35, textAlign: "center"}}>Klanten</Typography>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            spacing={4}>

                            <div style={{marginTop: -15}}>
                                <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <FilterListIcon />
                                        </Grid>
                                        <Grid item>
                                            <TextField label="Zoeken" inputProps={{ style:{width: 500} }} onChange={(e)=>handleFilter(e.target.value)} />
                                        </Grid>
                                </Grid>
                            </div>

                            <div style={{marginLeft: 40}}>
                                <Grid item>
                                <IconButton disabled={bestekkenLoading} onClick={() => {
                                    setSelectedCustomer(null);
                                    setShowDetailDialog(true);
                                }}>
                                    <AddIcon />
                                </IconButton>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div style={{ height: '80vh', width: '90vw' }}>
                            <DataGrid key={filteredCustomers?filteredCustomers:[]} columns={cols} rows={filteredCustomers?filteredCustomers:[]} sortModel={[{ field: 'partner_id', sort: 'desc' }]}></DataGrid>
                        </div>
                    </Grid>
                </>

                <CustomerDetail
                bestekkenClients={bestekkenClients}
                showDetailDialog={showDetailDialog}
                setShowDetailDialog={setShowDetailDialog}
                selectedCustomer={selectedCustomer}
                refetch={refetch}
                />
        </Grid>
    );
}

export default Customers;