import React, {useState} from 'react';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect, useGlobal } from 'reactn';
import { useMutation } from '@apollo/client';
import { QUERIES } from '../../../../api/queries';
import { makeStyles } from '@material-ui/core/styles';
import { showErrorToast, showInfoToast } from '../../../../util/toasts';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal+1,
    color: 'white',
  },
}));

const FiscalYearDetail=({securityRoles, showDetailDialog, setShowDetailDialog, selectedFiscalYear, refetch})=>{
    const classes = useStyles();
    const [authUser, setAuthUser] = useGlobal('authUser');

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
   
    const [hasValues, setHasValues] = useState(false);
    const [loading, setloading] = useState();

    const [createFiscalYearMutation] = useMutation(QUERIES.CREATE_FISCAL_YEAR);
    //const [updateFiscalYearMutation] = useMutation(QUERIES.UPDATE_FISCAL_YEAR);

    useEffect(()=>{
        if(!!selectedFiscalYear){
            setName(selectedFiscalYear.name);
            setStartDate(selectedFiscalYear.startDate);
            setEndDate(selectedFiscalYear.endDate);
        }
    },[selectedFiscalYear]);

    useEffect(()=>{
        setHasValues(valueChecker());
    },[name, startDate, endDate]);

    const handleClose=()=>{
        setShowDetailDialog(false);
        // setEmailInput("");
        // setPhoneInput("");
        // setFirstnameInput("");
        // setLastnameInput("");
        setloading(false);
    }

    const handleConfirm=async()=>{
        try{
            setloading(true);
            // if(!!selectedFiscalYear){
                // await updateFiscalYearMutation({
                //     variables: {
                //         fiscalYear: {
                //             id: selectedFiscalYear.id,
                //             name: name,
                //             start_date: startDate,
                //             end_date: endDate,
                //             modify_user: authUser.profileObj.email,
                //         }
                //     }
                // });
            // }else{
                await createFiscalYearMutation({
                    variables: {
                        fiscalYear: {
                            name: name,
                            start_date: startDate,
                            end_date: endDate,
                            create_user: authUser.profileObj.email,
                            modify_user: authUser.profileObj.email,
                        }
                    }
                });
            // }

            await refetch();
            handleClose();
            showInfoToast("Boekjaar succesvol verwerkt");
        }catch(err){
            //TODO: alert? wtf
            showErrorToast("Er ging iets mis bij het uitvoeren van de actie.");
            handleClose();
        }
    }

    const valueChecker=()=>{
        return !!name&&name!="" && !!startDate&&startDate!="" && !!endDate&&endDate!="" && !!authUser&&authUser.profileObj&&authUser.profileObj.email;
    }

  
    return(
        <>
        <Dialog open={showDetailDialog} onClose={handleClose} maxWidth="md" fullWidth={true}>
                    <DialogTitle>Boekjaar details</DialogTitle>
                    <Divider style={{width: "95%", margin: "auto", marginBottom: 15}} />
                    <DialogContent >
                        <div style={{width: "90%", margin: "auto"}}>
                            <TextField disabled={true} label="Boekjaar Id" style={{width: "40%", float: "left", padding: 5}} value={selectedFiscalYear?selectedFiscalYear.fiscalYear_id:"/"} />
                            <TextField label="Jaar" style={{width: "40%", float: "left", padding: 5}} defaultValue={name} onChange={(e)=>setName(e.target.value)} />
                            <TextField label="Startdatum (YYYY/MM/DD)" style={{width: "40%", float: "left", padding: 5, marginTop: 50}} defaultValue={startDate} onChange={(e)=>setStartDate(e.target.value)} />
                            <TextField label="Einddatum (YYYY/MM/DD)" style={{width: "40%", float: "left", padding: 5, marginTop: 50}} defaultValue={endDate} onChange={(e)=>setEndDate(e.target.value)} />
                            
                        </div>
                    </DialogContent>
                    <br style={{marginBottom: 15, marginTop: 15}} />
                    <DialogActions>
                        <Button color="secondary" onClick={handleClose}>
                            Annuleren
                        </Button>
                        <Button color="primary" disabled={!hasValues} onClick={handleConfirm}>
                            {!!selectedFiscalYear?"Bewaren":"Toevoegen"}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                </>
    );
}

export default FiscalYearDetail;