import React, { useEffect, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useQuery } from '@apollo/client';
import { QUERIES } from '../../api/queries';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import EmployeeDetail from './EmployeeDetail/EmployeeDetail';

const Employees = props => {

    const [employees, setEmployees] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [securityRoles, setSecurityRoles] = useState([]);

    const cols = [
        { field: 'employee_id', headerName: 'Werknemer Id', width: 150 },
        { field: 'firstname', headerName: 'Voornaam', width: 250 },
        { field: 'lastname', headerName: 'achternaam', width: 250 },
        { field: 'email', headerName: 'E-Mail', width: 250 },
        { field: 'contact_phone', headerName: 'GSM', width: 250 },
        { field: 'securityRole', headerName: 'Security Role', width: 350, renderCell: ({ value }) => (value.name) },
        { field: 'id', headerName: 'Bewerken', renderCell: ( {data} )=>{
                return (
                    <IconButton
                    disabled={securityRolesLoading}
                    onClick={() => {
                        setSelectedEmployee(data);
                        setShowDetailDialog(true);
                    }}>
                        <EditIcon />
                    </IconButton>
                );
        } },
    ];

    const { loading, error, data, refetch } = useQuery(QUERIES.EMPLOYEES, {
        fetchPolicy: 'cache-first',
        variables: {  }
    });

    const { loading:securityRolesLoading, error:securityRolesError, data:securityRolesData, refetch:securityRolesRefetch } = useQuery(QUERIES.SECURITYROLES, {
        fetchPolicy: 'cache-first',
        variables: {  }
    });

    useEffect(()=>{
        if(data&&data.employees)
            setEmployees(data.employees);
    },[data]);

    useEffect(()=>{
        handleFilter(searchString);
    },[employees]);

    useEffect(()=>{
        console.log(securityRolesData)
        if(securityRolesData&&securityRolesData.securityRoles)
            setSecurityRoles(securityRolesData.securityRoles);
    },[securityRolesData]);


    const handleFilter=(value)=>{
        value=value?value.toLowerCase():"";
        setSearchString(value);
        let employeesCopy = [...employees];
        employeesCopy=employeesCopy.filter((employee)=>{
            const firstname = (employee&&employee.firstname?employee.firstname:"").toLowerCase();
            const lastname = (employee&&employee.lastname?employee.lastname:"").toLowerCase();
            return firstname.includes(value) || lastname.includes(value);
        });
        setFilteredEmployees(employeesCopy);
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}>

                <>
                    <Grid item>
                        <Typography variant='h2' style={{marginBottom: 35, textAlign: "center"}}>Werknemers</Typography>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            spacing={4}>

                            <div style={{marginTop: -15}}>
                                <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <FilterListIcon />
                                        </Grid>
                                        <Grid item>
                                            <TextField label="Zoeken" inputProps={{ style:{width: 500} }} onChange={(e)=>handleFilter(e.target.value)} />
                                        </Grid>
                                </Grid>
                            </div>

                            <div style={{marginLeft: 40}}>
                                <Grid item>
                                <IconButton disabled={securityRolesLoading} onClick={() => {
                                    setSelectedEmployee(null);
                                    setShowDetailDialog(true);
                                }}>
                                    <AddIcon />
                                </IconButton>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <div style={{ height: '80vh', width: '90vw' }}>
                            <DataGrid key={filteredEmployees?filteredEmployees:[]} columns={cols} rows={filteredEmployees?filteredEmployees:[]} sortModel={[{ field: 'employee_id', sort: 'desc' }]}></DataGrid>
                        </div>
                    </Grid>
                </>

                <EmployeeDetail
                securityRoles={securityRoles}
                showDetailDialog={showDetailDialog}
                setShowDetailDialog={setShowDetailDialog}
                selectedEmployee={selectedEmployee}
                refetch={refetch}
                />
        </Grid>
    );
}

export default Employees;