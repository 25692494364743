import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERIES } from '../../../api/queries';
import axios from "axios";
import { Grid, TextField, Typography, IconButton, CircularProgress, Button, Alert } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getGlobal, useGlobal } from 'reactn';
import { showErrorToast, showInfoToast } from '../../../util/toasts';

const ticketManagerSecret = '5f7df0b1-1ffb-4265-a422-224ea0fa1ba4';

const TicketLogModal = ({ work, onClose, history, onComplete }) => {

    const [workDetails, setWorkDetails] = useState(null);
    const [loadTicketLogData, { called, loading, data, refetch }] = useLazyQuery(QUERIES.TICKETLOG_MODAL, { fetchPolicy: `network-only` });
    const [updateTicketLog] = useMutation(QUERIES.UPDATE_TICKETLOG);
    const [unlinkedTickets, setUnlinkedTickets] = useState(null);
    const [loadUnlinkendTickets] = useLazyQuery(QUERIES.TICKETLOGS_BY_WORK, {fetchPolicy: 'network-only', onCompleted: (res) => {
        console.log(res);
        if (res.atnTicketLogs) {
            setUnlinkedTickets(res.atnTicketLogs);
            }
        }
    });

    const [currentUser, setCurrentUser] = useGlobal('currentUser');
    const [operationRunning, setOperationRunning] = useState(false);

    const linkUnlinkedTickets = async () => {
        const res = await loadUnlinkendTickets({variables: {filter: {unassigned: true}}});
        
    }

    useEffect(() => {
        if (!!work) {
            loadTicketLogData({ variables: { id: work.id } });
        }
    }, [work]);

    useEffect(async () => {
        if (!!unlinkedTickets) {
            if (unlinkedTickets.length > 0) {
                for (let t of unlinkedTickets) {
                    let ticketToUpdate = {id: t.id, fk_ctx_work: work.id};
                    updateTicketLog({variables: {ticketLog: ticketToUpdate}});
                }
                await setOperationRunning(false);
                showInfoToast(`${unlinkedTickets.length} tickets gelinkt!`)
                onComplete();
            }
            else {
                await setOperationRunning(false);
                showInfoToast('Geen ongelinkte tickets gevonden');
            }
        }
    }, [unlinkedTickets])

    useEffect(() => {
        if (!!data) {
            if (!!data.atnWork) {
                // delete data.atnWork.__typename;
                setWorkDetails(data.atnWork);
                console.log(process.env.NODE_ENV);
            }
        }
    }, [data]);

    if (!!loading || operationRunning) {
        return (
            <Grid
                style={{ width: '10vw', height: '40vh', overflow: 'hidden' }}
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={0}>
                <Grid item >
                    <CircularProgress />
                </Grid>
            </Grid>
        )
    }
    if (!!data && !!workDetails) {
        return (
            <div style={{ padding: '32px' }}>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    spacing={4}>
                    <Grid item>
                        <Button onClick={() => {
                            onClose();
                        }}>X</Button>
                    </Grid>
                </Grid>
                <h1>{`TicketLogs - ${work.name}`}</h1>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow key={1}>
                                <TableCell align="left">Open tickets</TableCell>
                                <TableCell align="right">{workDetails.open_tickets}</TableCell>
                            </TableRow>
                            <TableRow key={2}>
                                <TableCell align="left">Gesloten tickets</TableCell>
                                <TableCell align="right">{workDetails.closed_tickets}</TableCell>
                            </TableRow>
                            <TableRow key={3}>
                                <TableCell align="left">Totaal werkuren (Tijd)</TableCell>
                                <TableCell align="right">{(`${workDetails.work_duration}`).replace(/\./g, ',')} uur</TableCell>
                            </TableRow>
                            <TableRow key={4}>
                                <TableCell align="left">Totaal werkuren (Bedrag)</TableCell>
                                <TableCell align="right">€ {(`${workDetails.work_balance}`).replace(/\./g, ',')}</TableCell>
                            </TableRow>
                            <TableRow key={5}>
                                <TableCell align="left">Totaal materialen</TableCell>
                                <TableCell align="right">€ {(`${workDetails.material_balance}`).replace(/\./g, ',')}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid
                    style={{ marginTop: '8px' }}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={4}>
                    <Grid item>
                        <Button
                            Button variant='contained'
                            color="primary"
                            onClick={async () => {
                                try {
                                    setOperationRunning(true);
                                    await linkUnlinkedTickets();
                                    if (!!unlinkedTickets) {
                                        if (unlinkedTickets.length > 0) {
                                            showInfoToast("Tickets gelinkt!")
                                        }
                                    }
                                    // return onComplete();
                                }
                                catch (err) {
                                    console.error(err);
                                    setOperationRunning(false);
                                    showErrorToast("Er ging iets mis bij het linken");
                                }
                                // implement verwerken
                            }}>Ongelinkte Tickets linken</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            color="primary"
                            onClick={async () => {
                                try {
                                    setOperationRunning(true);
                                    const ctlRes = await axios({
                                        method: 'post',
                                        headers: {
                                            authorization: ticketManagerSecret
                                        },
                                        url: 'https://europe-west1-workorders-285607.cloudfunctions.net/TicketManager',
                                        data: {
                                            authorization: ticketManagerSecret,
                                            action: 'createDeclaration',
                                            recordId: work.id,
                                            projectFolder: work.project.folder_id,
                                            email: currentUser.email,
                                            env: process.env.NODE_ENV
                                        }
                                    });
                                    console.log(ctlRes);
                                    showInfoToast("Afrekening gemaakt!");
                                    return onComplete();
                                }
                                catch (err) {
                                    console.error(err);
                                    setOperationRunning(false);
                                    showErrorToast("Er ging iets mis bij het maken van de afrekening.");
                                }
                            }}>Afrekening maken</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            Button variant='contained'
                            color="primary"
                            onClick={async () => {
                                try {
                                    setOperationRunning(true);
                                    const ctlRes = await axios({
                                        method: 'post',
                                        headers: {
                                            authorization: ticketManagerSecret
                                        },
                                        url: 'https://europe-west1-workorders-285607.cloudfunctions.net/TicketManager',
                                        data: {
                                            authorization: ticketManagerSecret,
                                            action: 'closeTicketlogs',
                                            recordId: work.id,
                                            env: process.env.NODE_ENV
                                        }
                                    });
                                    console.log(ctlRes);
                                    showInfoToast("Ticketlogs afgesloten!")
                                    return onComplete();
                                }
                                catch (err) {
                                    console.error(err);
                                    setOperationRunning(false);
                                    showErrorToast("Er ging iets mis bij het sluiten van de tickets.");
                                }
                                // implement verwerken
                            }}>Ticketlogs verwerken</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => {
                            // implement declaration
                            history.push('/ticketlogs/' + work.id);
                        }}>Naar Ticketlogs</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
    return (
        <Grid
            style={{ width: '20vw', height: '20vw', overflow: 'hidden' }}
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}>
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    )
}

export default TicketLogModal;
